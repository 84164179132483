import React from 'react';
import { useHistory} from 'react-router-dom';
import landscape from '../images/Landscape/1.jpg';
import portrait from '../images/webIcons/port.jpg';
import { useMediaQuery } from 'react-responsive';


function Portfolio() {

    let history = useHistory();

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

    const onSubmit = (value) => {
       console.log(value);
       history.push('/'+value)
     }

    return (
        <div className='Portfolio'>
            <div onClick={() => onSubmit('landscapes')} className={`${isTabletOrMobile ? "phoneMediaHome" : " mediaHome"}`}>
                <p>Landscape Photography</p>
                <img src={landscape} alt='landscape'></img>
            </div>
            <div onClick={() => onSubmit('portraits')}  className={`${isTabletOrMobile ? "phoneMediaHome" : " mediaHome"}`}>
                <p>Portrait Photography</p>
                <img src={portrait} alt='portraits'></img>
            </div>
            
        </div>
    );
}

export default Portfolio;