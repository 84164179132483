import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';

class Experience extends Component {
  render() {
    return(
      <Grid>
        <Cell col={4}>
          <p>{this.props.startYear} - {this.props.endYear}</p>
        </Cell>
        <Cell col={8}>
          <div className="companyImage">
          <img className= "experience-img" src={this.props.img} alt="companylogo"></img>
          {/* <h4 className='companyTitle'>{this.props.jobName}</h4> */}
          </div>
          <p>{this.props.jobDescription}</p>
        </Cell>
      </Grid>
    )
  }
}

export default Experience;