import React from 'react';
import { Grid, Cell, List, ListItem, ListItemContent } from 'react-mdl';
import avatar from '../images/webIcons/ItsMe.jpg';
import { useMediaQuery } from 'react-responsive';

function Contact(){
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    
    return(
        <div className='contact-body'>
            <Grid className= 'contact-grid'>
                <Cell col={6}>
                    <h2 className='myName'> Lokesh Paduchuri</h2>
                    <img 
                    src={avatar}
                    alt="avatar"
                    className="contactMeAvatar"
                    />
                    <p className= 'myIntroClass'>Full time Web-developer and a Passionate Photographer</p>
                </Cell>
                <Cell col={6} className={`contact-grid-class ${isTabletOrMobile ? "phoneContactClass" : " "}`}>
                    <h2 className="contactClass">Contact Me</h2>
                    <hr className='underline'/>
                    <div className='contact-list'>
                        <List>
                            <ListItem>
                                <ListItemContent className='listItemStyles'>
                                    <i className="fa fa-envelope iconFont"/>lokeshpui@gmail.com
                                </ListItemContent>
                            </ListItem>
                            <ListItem>
                                <ListItemContent className='listItemStyles' onClick={() => window.open("https://www.instagram.com/lokip_photography/", "_blank")}>
                                    <i className="fa fa-instagram iconFont"/>lokip_photography
                                </ListItemContent>
                            </ListItem>
                        </List>
                    </div>
                </Cell>
            </Grid>
        </div>
    )
    
}

export default Contact;