

export var portraits_info = [ ];
export var landscape_info = [ ];

function importAllPorts(r) {
    r.keys().forEach(elem => {
        portraits_info.push({name: elem})
    })
    return r.keys().map(r);
}

function importAllLandscapes(r) {
r.keys().forEach(elem => {
    landscape_info.push({name: elem})
})
return r.keys().map(r);
}
  
const images = importAllPorts(require.context('../images/Portraits', false, /\.(png|jpe?g|svg)$/));

const landscapeImages = importAllLandscapes(require.context('../images/Landscape', false, /\.(png|jpe?g|svg)$/));
for(let i =0 ; i< portraits_info.length; i++){
    portraits_info[i].src = images[i];
}

for(let i =0 ; i< landscape_info.length; i++){
    landscape_info[i].src = landscapeImages[i];
}